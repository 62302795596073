<template>
    <div>
        <CRow>
            <CCol sm="12" md="12">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol sm="6">
                                <h4 class="card-title mb-0">{{title}}</h4>
                            </CCol>
                            <CCol sm="6">
                                <div class="float-right" >
                                    <CButton color="primary">
                                        New Subject
                                    </CButton>  
                                </div>
                            </CCol>
                        </CRow>
                        
                        <CRow class="mt-4" >
                            <CCol col="12">
                            
                                <CDataTable
                                    hover
                                    striped
                                    :items="subjects"
                                    :fields="subject_fields"
                                    :active-page="activePage"
                                    :items-per-page="3"
                                    :pagination="{ doubleArrows: false, align: 'center'}"
                                    @page-change="pageChange"
                                >
                                    
                                    <template #actions="{item}">
                                        <td>
                                            <CLink to="#" class="mr-3">
                                                Edit
                                            </CLink>
                                            <CLink to="#">
                                                Delete
                                            </CLink>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CCardBody>

                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
export default {
    name:'Subjects',
    data(){
        return{
            showsubjects:false,
            activePage: 1,
            subjects:[
                { id: 1, name: 'Math', code: 'math' },
                { id: 2, name: 'English', level: 'eng'},
                { id: 3, name: 'Science', level: 'sci'}

            ],
            subject_fields:[
                { key: 'id', label: 'S/No' },
                { key: 'name', label: 'Name' },
                { key: 'actions', label: 'Actions' }
            ],
            title:"Subjects",
     
            filter:{
                subject:'',
                topic:'',
                sub_topic:'',
                difficulty_level:'',
                keyword:''
            },

        }
    },
    watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
    methods:{
        pageChange (val) {
            this.$router.push({ query: { page: val }})
        },
        searchWS()
        {
            this.showsubjects = true
        }
    }
}
</script>